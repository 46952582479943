import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/table/customTable";
import { RoutePath } from "../../routes/routePath";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import userGlobalConfig from "../../utils/constants/globalConfig";
import { useNavigate } from "react-router-dom";
import {
  journeyData,
  journeyTableHeaderList,
} from "../../utils/seeds/tableHeaders";
import UserListRow from "../../components/table/tableRows/userListRow";
import CustomModal from "../../components/customModal/customModal";
import { useFormik } from "formik";
import {
  addJourneyInitialValues,
  addJourneyValidationSchema,
} from "../../utils/validations/joureny";
import JourneyListRow from "../../components/table/tableRows/journeyListRow";
import { toast } from "react-toastify";
import usePost from "../../hooks/usePost";
import JourneyCard from "../../components/journey/journeyCard";
import JourneySkeleton from "../../utils/skeleton/journeySkeleton";

const JourneyList = () => {
  const [countData, setCountData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [uploadImg, setUploadImg] = useState(null);
  const [uploadBackgroundImg, setUploadBackgroundImg] = useState(null);
  const [uploadQuestionImg, setUploadQuestionImg] = useState(null);
  const [addJourneyModal, setAddJourneyModal] = useState(false);
  const nav = useNavigate();
  const uploadImageRef = useRef(null);
  const uploadImageBackgroundRef = useRef(null);
  const uploadQuestionImageRef = useRef(null);
  const {
    data: journeyListData,
    isLoading: isLoadingJourney,
    error: errorJourney,
    isSuccess: isSuccessJourney,
    refetch: refetchJourney,
  } = useGet(ENDPOINTS.LIST_JOURNEY, QUERY_KEYS.LIST_JOURNEY_QUERY_KEY, {
    page: pageNumber,
    limit: countData,
  });
  const { mutateAsync: addJourneyMutateAsync, isLoading: isLoadingAddJourney } =
    usePost(ENDPOINTS.ADD_JOURNEY, QUERY_KEYS.ADD_JOURNEY_QUERY_KEY);
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: addJourneyInitialValues,
    validationSchema: addJourneyValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();

      formData.append("name", values?.journeyName);
      formData.append("description", values?.journeyDescription);
      formData.append("image", values?.journeyImage);
      formData.append("background_image", values?.journeyBackgroundImg);
      formData.append("assessment_question", values?.journeyQuestion);
      formData.append("assessment_image", values?.journeyQuestionImage);
      try {
        const response = await addJourneyMutateAsync(formData);
        if (response.statusCode === 201) {
          toast.success(response?.message);
          setAddJourneyModal(false);
          resetForm();
          refetchJourney();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });
  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("journeyImage", selectedFile);
    target.value = "";
  };
  const onChangeBackgroundFile = (target) => {
    const selectedFile = target.files[0];
    setUploadBackgroundImg(URL.createObjectURL(selectedFile));
    setFieldValue("journeyBackgroundImg", selectedFile);
    target.value = "";
  };
  const onChangeQuestionFile = (target) => {
    const selectedFile = target.files[0];
    setUploadQuestionImg(URL.createObjectURL(selectedFile));
    setFieldValue("journeyQuestionImage", selectedFile);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handleSelectUploadBackgroundImage = () => {
    if (uploadImageBackgroundRef.current) {
      uploadImageBackgroundRef.current.click();
    }
  };
  const handleSelectUploadQuestionImage = () => {
    if (uploadQuestionImageRef.current) {
      uploadQuestionImageRef.current.click();
    }
  };
  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("journeyImage", "");
  };
  const handelRemoveUploadBackgroundImg = () => {
    setUploadBackgroundImg(null);
    setFieldValue("journeyBackgroundImg", "");
  };
  const handelRemoveQuestionImg = () => {
    setUploadQuestionImg(null);
    setFieldValue("journeyQuestionImage", "");
  };

  const handelView = (item) => {
    nav(RoutePath.JOURNEY_DETAIL.path, {
      state: { headerName: RoutePath.JOURNEY_DETAIL.title },
    });
    localStorage.setItem(
      userGlobalConfig.JOURNEY_ID,
      JSON.stringify(item?._id)
    );
  };
  const handlePrevPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue) => {
    if (
      pageNumber === Math.ceil(journeyListData?.data?.journyList / countData)
    ) {
      setPageNumber(1);
    }
    setCountData(newValue);
  };
  const handelAddJourneyModal = () => {
    setAddJourneyModal(true);
  };
  useEffect(() => {
    refetchJourney();
  }, [pageNumber, countData]);
  return (
    <div>
      {/* <CustomTable
        isLoading={isLoadingJourney}
        heading={"Journey List"}
        tableHeaderList={journeyTableHeaderList}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleRowLimitChange={handleRowLimitChange}
        currentPage={pageNumber}
        totalPages={journeyListData?.data?.totalRecords}
        countData={countData}
        addBtn={true}
        addBtnTitle={"Add Journey"}
        addBtnAction={handelAddJourneyModal}
      >
        <JourneyListRow
          onView={handelView}
          data={journeyListData?.data?.journyList}
        />
      </CustomTable> */}
      <div className="container-fluid py-0 px-0">
        {isLoadingJourney ? null : (
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-md btn-green mt-0 mb-4"
              onClick={handelAddJourneyModal}
            >
              Add Journey
            </button>
          </div>
        )}

        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 gy-4 gx-4">
          {isLoadingJourney ? (
            <JourneySkeleton />
          ) : (
            journeyListData?.data?.journyList?.map((item, index) => {
              return (
                <JourneyCard data={item} key={index} onView={handelView} />
              );
            })
          )}
        </div>
      </div>

      <CustomModal
        open={addJourneyModal}
        title={"Add Journey"}
        actionTitle={"Add"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={handleSubmit}
        isLoading={isLoadingAddJourney}
        onClose={() => {
          setAddJourneyModal(false);
          resetForm();
        }}
      >
        <div>
          <form>
            <div className="upload_container">
              <div>
                <div className="drag-input-field">
                  <label>Upload Image </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="journeyImage"
                    onBlur={handleBlur}
                    onChange={({ target }) => {
                      onChangeFile(target);
                    }}
                    ref={uploadImageRef}
                  />
                  {values.journeyImage.length === 0 ? (
                    <label className="m-0" onClick={handleSelectUploadImage}>
                      <span className="upload-text">
                        <i className="fa-solid fa-cloud-arrow-up" />
                        Upload
                      </span>
                    </label>
                  ) : (
                    <div className="position-relative ">
                      <img
                        src={uploadImg}
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        alt="..."
                      />
                      <button
                        className="removeimg"
                        type="button"
                        onClick={() => handelRemoveUploadImg()}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
                {touched.journeyImage && errors.journeyImage && (
                  <p className="error" style={{ marginTop: "4px" }}>
                    {errors.journeyImage}
                  </p>
                )}
              </div>
              <div>
                <div className="mb-3">
                  <label> Name</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Journey Name"
                    name="journeyName"
                    value={values.journeyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.journeyName && errors.journeyName && (
                  <p className="error">{errors.journeyName}</p>
                )}
                <div className="mb-3">
                  <label> Description </label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter Journey Description"
                    rows="3"
                    cols="50"
                    name="journeyDescription"
                    value={values.journeyDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.journeyDescription && errors.journeyDescription && (
                  <p className="error">{errors.journeyDescription}</p>
                )}
              </div>
            </div>

            <div className="drag-input-field mt-1 ">
              <label>Upload Background Image </label>
              <input
                type="file"
                accept="image/*"
                name="journeyBackgroundImg"
                onBlur={handleBlur}
                onChange={({ target }) => {
                  onChangeBackgroundFile(target);
                }}
                ref={uploadImageBackgroundRef}
              />
              {values.journeyBackgroundImg.length === 0 ? (
                <label
                  className="m-0"
                  onClick={handleSelectUploadBackgroundImage}
                >
                  <span className="upload-text">
                    <i className="fa-solid fa-cloud-arrow-up" />
                    Upload image
                  </span>
                </label>
              ) : (
                <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                  <img
                    src={uploadBackgroundImg}
                    style={{
                      height: "200px",
                      width: "200px",

                      objectFit: "contain",
                      borderRadius: "5px",
                      border: "solid 1px #8080808a",
                    }}
                    alt="..."
                  />
                  <button
                    className="removeimg"
                    type="button"
                    onClick={() => handelRemoveUploadBackgroundImg()}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            {touched.journeyBackgroundImg && errors.journeyBackgroundImg && (
              <p className="error" style={{ marginTop: "4px" }}>
                {errors.journeyBackgroundImg}
              </p>
            )}

            <div className="upload_container mt-1">
              <div>
                <div className="drag-input-field">
                  <label>Upload Question Image </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="journeyQuestionImage"
                    onBlur={handleBlur}
                    onChange={({ target }) => {
                      onChangeQuestionFile(target);
                    }}
                    ref={uploadQuestionImageRef}
                  />
                  {values.journeyQuestionImage.length === 0 ? (
                    <label
                      className="m-0"
                      onClick={handleSelectUploadQuestionImage}
                    >
                      <span className="upload-text">
                        <i className="fa-solid fa-cloud-arrow-up" />
                        Upload
                      </span>
                    </label>
                  ) : (
                    <div className="position-relative ">
                      <img
                        src={uploadQuestionImg}
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        alt="..."
                      />
                      <button
                        className="removeimg"
                        type="button"
                        onClick={() => handelRemoveQuestionImg()}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
                {touched.journeyQuestionImage &&
                  errors.journeyQuestionImage && (
                    <p className="error" style={{ marginTop: "4px" }}>
                      {errors.journeyQuestionImage}
                    </p>
                  )}
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label> Question </label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter Question"
                    rows="7"
                    cols="50"
                    name="journeyQuestion"
                    value={values.journeyQuestion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.journeyQuestion && errors.journeyQuestion && (
                  <p className="error">{errors.journeyQuestion}</p>
                )}
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
};

export default JourneyList;
